import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Authentication from './Authentication';
import Invoice from './ServicesAndSubscriptions/Invoice';
import AppLayout from '../components/layouts/AppLayout';
import { appLaunchFlow } from '../redux/authentication/action';
import SubscriptionsAndServices from './ServicesAndSubscriptions';
import { Redirect } from 'react-router';
import Products from './Products';
import { RootState } from 'redux/root-reducer';
import { b2bUserRegistrationPayload } from 'data/b2bUserRegistration';
import { createB2BUser } from 'redux/prouser/action';
import { getUser } from 'redux/graphql/authentication/actions';
import { createCardID, getCardDetails } from 'redux/cart/actions';

// import SignIn from './SignIn';

const Routes: FC = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const appLaunch = useCallback(() => dispatch(appLaunchFlow()), [dispatch]);
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);
    const userDetails = useSelector((state: RootState) => state.userDetails?.queries);
    const { id, countryCode, firstName, lastName, contactEmail, primaryPhoneNumber, partnerUsers } =
        userDetails?.FETCH_USER_DETAILS?.data?.viewer || {};
    const { status, shipToAddress, billToAddress, name } =
        partnerUsers?.edges[0]?.node.partnerAccount || {};
    const partnerAccountId = partnerUsers?.edges[0]?.node.partnerAccount.id;
    const URL =
        process.env.REACT_APP_SIGNUP_URL || 'https://develop--resideo-pro.netlify.app/get-started';
    const getCartId: any = useSelector((state: RootState) => state.cartDetails.cartIDDetails);



    useEffect(() => {
        appLaunch();
    }, [appLaunch, dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getUser());
            dispatch(createCardID());
        }
    }, [dispatch, isAuthenticated])

    useEffect(() => {
        if (id && billToAddress && primaryPhoneNumber) {
            billToAddress.phone = primaryPhoneNumber;
            shipToAddress.phone = primaryPhoneNumber;
            const allAddress = [billToAddress, shipToAddress];
            const dataToSend = b2bUserRegistrationPayload(
                contactEmail,
                firstName,
                lastName,
                id,
                primaryPhoneNumber,
                allAddress,
                partnerAccountId,
                status,
                name,
            );
            dispatch(createB2BUser({ b2BCustomerWsDTO: dataToSend }));
        }
    }, [
        billToAddress,
        contactEmail,
        countryCode,
        dispatch,
        firstName,
        id,
        lastName,
        name,
        partnerAccountId,
        primaryPhoneNumber,
        shipToAddress,
        status,
    ]);

    useEffect(() => {        
            dispatch(getCardDetails());            
    }, [getCartId]);


    return (
        <Switch>
            <AppLayout>
                <Route path='/auth' component={Authentication} />
                <Route
                    path='/get-started'
                    component={() => {
                        window.location.href = URL;
                        return null;
                    }}
                />
                <Route path='/'>
                    <Products />
                </Route>
                <Route path='/profile'></Route>
                <Route path='/subscription'>
                    <SubscriptionsAndServices />
                </Route>
                <Route path='/admin-console'></Route>
                <Route path={`/invoice/:id`} component={Invoice} />
            </AppLayout>
        </Switch>
    );
};

export default Routes;

import React, { FC, useMemo } from 'react';
import { Environment, RecordSource, Store, Network } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { GATEWAY_URL } from 'config';
import { fetchQueryFactory } from './network';

interface GraphQLProviderInterface {
  environment?: Environment;
}

const GraphQLProvider: FC<GraphQLProviderInterface> = ({
  children,
  environment,
}): JSX.Element => {
  const url = GATEWAY_URL;

  const relayEnvironment = useMemo(() => {
    const token: string | undefined = localStorage.getItem('jwt') || undefined;
    return (
      environment ||
      new Environment({
        network: Network.create(
          fetchQueryFactory({ accessToken: token, url })
        ),
        store: new Store(new RecordSource()),
      })
    );
  }, [environment, url]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
};

export default GraphQLProvider;

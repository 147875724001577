export const parseCreditCard = (rawData: any) => {
    const model = {
        id: '',
        paymentSourceId: '',
        clientSecret: '',
        state: 'CHARGEABLE',
        type: 'CREDIT_CARD',
        paymentSessionId: '',
        currency: 'USD',
        creditCard: {
            lastFourDigits: '',
            expirationMonth: 0,
            expirationYear: 0,
            brand: '',
        },
        billTo: {
            firstName: '',
            lastName: '',
            email: null,
            organization: null,
            address: {
                addressLine1: '',
                addressLine2: null,
                city: '',
                stateProvinceRegionCode: '',
                zipPostalCode: '',
                countryCode: '',
                mailingAddressName: null,
                contactName: null,
            },
        },
    };

    if (!rawData) return model;
    if (rawData?.id) {
        model.id = rawData?.id;
    }
    if (rawData.subscriptionId) {
        const [paymentSourceId, clientSecret] = rawData?.subscriptionId?.split('|');
        model.paymentSourceId = paymentSourceId;
        model.clientSecret =  clientSecret;
    }
    if (rawData.cardNumber) {
        const lastFourNums = rawData?.cardNumber.slice(-4);
        const expiryYear = rawData?.expiryYear.slice(-2);
        model.creditCard.lastFourDigits = lastFourNums;
        model.creditCard.expirationMonth = parseInt(rawData?.expiryMonth);
        model.creditCard.expirationYear = parseInt(expiryYear);
        model.creditCard.brand = rawData?.cardType?.name?.toUpperCase()?.replace(/ /g,"_") ?? rawData?.cardType?.code?.toUpperCase();
    }
    if (rawData.accountHolderName) {        
        const [firstName, lastName] = rawData?.accountHolderName.split(' ');
        const [countryCode, stateCode] = rawData?.billingAddress?.region?.isocode.split('-');
        model.billTo.firstName = firstName;
        model.billTo.lastName = lastName;
        model.billTo.email = rawData?.email;
        model.billTo.address.addressLine1 = rawData?.billingAddress?.line1;
        model.billTo.address.addressLine2 = rawData?.billingAddress?.line2;
        model.billTo.address.city = rawData?.billingAddress?.town;
        model.billTo.address.stateProvinceRegionCode = stateCode;
        model.billTo.address.zipPostalCode = rawData?.billingAddress?.postalCode;
        model.billTo.address.countryCode = rawData?.billingAddress?.country?.isocode;
        model.billTo.address.contactName = rawData?.accountHolderName;
    }

    return model;
};

export const parseCreditCardDetails = (rawData: Array<any>) => {
    const finalArray: Array<any> = [];
    rawData.forEach((creditCard) => {
        finalArray.push(parseCreditCard(creditCard));
    });
    return finalArray;
};

import { gql } from '@redux-requests/graphql';
import { types } from './types';

export const graphQltest = (payload?: string) => ({
    type: types.FETCH_USER_DETAILS,
    request: {
        query: gql`
            {
                companies {
                    name
                    contactPerson
                }
            }
        `,
        variables: { payload },
    },
});

export const partnerProgramServiceTest = () => ({
    type: types.FETCH_USER_DETAILS,
    request: {
        query: gql`
            {
                partnerPrograms {
                    edges {
                        node {
                            partnerProgramProducts {
                                edges {
                                    node {
                                        id
                                        partnerProgram {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    },
});

export const getUser = () => ({
    type: types.FETCH_USER_DETAILS,
    request: {
        query: gql`
            query ProfileQuery {
                viewer {
                    id
                    countryCode
                    firstName
                    lastName
                    contactEmail
                    primaryPhoneNumber
                    partnerUsers {
                        edges {
                            node {
                                ... on PartnerUser {
                                    partnerAccount {
                                        id
                                        status
                                        name
                                        billToAddress {
                                            addressLine1
                                            addressLine2
                                            city
                                            stateProvinceRegionCode
                                            zipPostalCode
                                            countryCode
                                            mailingAddressName
                                            contactName
                                        }
                                        shipToAddress {
                                            addressLine1
                                            addressLine2
                                            city
                                            stateProvinceRegionCode
                                            zipPostalCode
                                            countryCode
                                            mailingAddressName
                                            contactName
                                        }
                                        productMarkets {
                                            name
                                        }
                                        ... on PartnerAccount {
                                            partnerProgramEnrollments {
                                                edges {
                                                    node {
                                                        id
                                                        __typename
                                                        partnerProgram {
                                                            name
                                                        }
                                                        status
                                                        activationDate
                                                        billingCycle
                                                        enrollmentMarket
                                                        purchaseDate
                                                        cancellationDate
                                                        cancellationReason
                                                        autoRenew
                                                        ... on HomeownerAppBrandingPartnerProgramEnrollment {
                                                            logoApproved
                                                        }
                                                        subscriptionEndDate
                                                        billableQuantity
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    phoneNumbers {
                        number
                    }
                }
            }
        `,
        variable: {}
    },
});

export const getSuccess = (payload: any) => ({
    type: types.FETCH_USER_DETAILS_SUCCESS,
    payload,
});

export const getFail = (payload: any) => ({
    type: types.FETCH_USER_DETAILS_FAIL,
    payload,
});

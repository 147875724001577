/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex } from '@resideo/blueprint-react';
import { useSelector, useDispatch } from 'react-redux';
import ProductCard, { Product } from '../../../components/common/ProductCard';
import {  useRelayEnvironment } from 'relay-hooks';
// import { graphql } from 'react-relay/hooks';
import { graphql } from 'babel-plugin-relay/macro';
import { getProductList } from '../../../redux/productlist/actions';
import PageLoading from '../../../components/common/PageLoading';
import PageError from '../../../components/common/PageError';
import { Redirect, useHistory } from 'react-router';
import { RootState } from '../../../redux/root-reducer';
import { getCategory } from '../../../redux/category/actions';
import {
    Container,
    LeftContainer,
    RightContainer,
    FilterByText,
    CategoryText,
    ProductContainer,
    List,
    MyPaginate,
    StyledHeading,
    ProductLayoutContainer,
    BreadCrumbContainer,
    BreadCrumbClearAllButton,
    BreadCrumbItems,
    BreadCrumbListItem,
    BreadCrumbText,
    BreadCrumbUnOrderList,
    BreadCrumbButton,
    BreadCrumbButtonIcon,
} from './styles';
import { Item } from 'components/ProductList/Item';
import { leftCarouselArrow, rightCarouselArrow } from 'assets/images';
import { createCardID, getCardDetails } from 'redux/cart/actions';
import ProductTypeTopNav from './ProductTypeTopNav';
import Breadcrumbs from 'components/productdetails/Breadcrumbs';
import FacetFilter from 'components/ProductList/FacetFilter';
import { signIn } from 'config/Auth';
import { getJWtDetails } from 'utils/utils';
import { getUser } from 'redux/graphql/authentication/actions';
import { fetchQuery } from 'relay-runtime';

const ProductListPage = () => {
    const isProductListLoading = useSelector(
        (state: RootState) => state.productListData.fetchingProductList,
    );
    const { isSignedIn } = getJWtDetails();
    const { isLoggedIn, loginInProgress } = useSelector((state: RootState) => state.authentication);
    const error = useSelector((state: RootState) => state.productListData.fetchingProductListError);
    const productListData: any = useSelector(
        (state: RootState) => state.productListData.productList,
    );
    const categoryList: any = useSelector((state: RootState) => state.category.category);
    const isCategoryListLoading = useSelector(
        (state: RootState) => state.category.fetchingcategory,
    );
    const dispatch = useDispatch();

    const [isPageNumber, setIsPageNumber] = useState('0');
    const [categoryID, setCategoryID] = useState('');
    const [updateCategoryID, setUpdateCategoryID] = useState('');
    const [isSelectedCategoryID, setIsSelectedCategoryID] = useState<string | undefined | null>('');
    const [isCurrentPageNumber, setIsCurrentPageNumber] = useState(0);
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);
    const environment = useRelayEnvironment();

    const enrollmentData = useCallback(() => {
        if (isSignedIn) {
          fetchQuery(
            environment,
            graphql`
            query ProductListPageQuery {
                viewer {
                    id
                    countryCode
                    firstName
                    lastName
                    contactEmail
                    primaryPhoneNumber
                    partnerUsers {
                        edges {
                            node {
                                ... on PartnerUser {
                                    partnerAccount {
                                        id
                                        status
                                        name
                                        billToAddress {
                                            addressLine1
                                            addressLine2
                                            city
                                            stateProvinceRegionCode
                                            zipPostalCode
                                            countryCode
                                            mailingAddressName
                                            contactName
                                        }
                                        shipToAddress {
                                            addressLine1
                                            addressLine2
                                            city
                                            stateProvinceRegionCode
                                            zipPostalCode
                                            countryCode
                                            mailingAddressName
                                            contactName
                                        }
                                        productMarkets {
                                            name
                                        }
                                        ... on PartnerAccount {
                                            partnerProgramEnrollments {
                                                edges {
                                                    node {
                                                        id
                                                        __typename
                                                        partnerProgram {
                                                            name
                                                        }
                                                        status
                                                        activationDate
                                                        billingCycle
                                                        enrollmentMarket
                                                        purchaseDate
                                                        cancellationDate
                                                        cancellationReason
                                                        autoRenew
                                                        ... on HomeownerAppBrandingPartnerProgramEnrollment {
                                                            logoApproved
                                                        }
                                                        subscriptionEndDate
                                                        billableQuantity
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    phoneNumbers {
                        number
                    }
                }
            }
            `,
            {
            }
          ).subscribe({
            next: data => {
              console.log(data);
            },
            error: (error) => {
             console.log(error, 'The error we have sis here')
            },
          });
        } else {
          
        }
      }, [environment, isSignedIn]);
    const cartItems: any = useSelector((state: RootState) => state.cartDetails.cartDetails);
    const getCartId: any = useSelector((state: RootState) => state.cartDetails.cartIDDetails);    

    const userDetails = useSelector((state: RootState) => state.userDetails?.queries);
    const { id, countryCode, firstName, lastName, contactEmail, primaryPhoneNumber, partnerUsers } =
        userDetails?.FETCH_USER_DETAILS?.data?.viewer || {};

    const { status, shipToAddress, billToAddress, productMarkets, name } =
        partnerUsers?.edges[0]?.node.partnerAccount || {};
    const partnerAccountId = partnerUsers?.edges[0]?.node.partnerAccount.id;

    const getContactInfo = async () => {
        // dispatch(getUser());
    };

    useEffect(() => {
        document.title = 'Products | Resideo Pro';
        enrollmentData();
    }, []);

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         dispatch(createCardID());
    //         //getContactInfo();
    //     }
    // }, [isAuthenticated]);

    useEffect(() => {
        if (cartItems && !cartItems.length) {
            dispatch(getCardDetails());
        }        
    }, [getCartId]);

    useEffect(() => {
        setUpdateCategoryID(productListData.categoryCode);
    }, [productListData]);

    useEffect(() => {
        if (categoryList && categoryList.categories && categoryList.categories.length > 0) {
            setCategoryID(categoryList?.categories[0]?.id);
            setIsSelectedCategoryID(categoryList?.categories[0]?.name);
        }
    }, [categoryList]);

    useEffect(() => {
        if (categoryID) {
            dispatch(getProductList({ id: categoryID, pageNumber: isPageNumber }));
        }
    }, [categoryID]);

    const getProducts = async (id) => {
        setIsSelectedCategoryID(id);
        await dispatch(getProductList({ id, pageNumber: isPageNumber }));
    };

    const handlePaginationOnClick = async (data) => {
        await dispatch(getProductList({ id: updateCategoryID, pageNumber: data.selected }));
        setIsCurrentPageNumber(data.selected);
        window.scroll({top:0,behavior:'smooth'})
    };

    useEffect(() => {
        if (window.location.pathname === '/get-started' && !isLoggedIn) {
            const URL =
                process.env.REACT_APP_SIGNUP_URL ||
                'https://develop--resideo-pro.netlify.app/get-started';
            window.location.replace(URL as string);
        }
    }, [isLoggedIn]);

    return isCategoryListLoading ? (
        <PageLoading />
    ) : error ? (
        <PageError />
    ) : (
        <Box
            flex='1'
            justifyContent='center'
            flexDirection='row'
            width='100%'
            height='100%'
            backgroundColor='#ededed'
            data-test-productpage>
            <Box>
                <ProductTypeTopNav />
                <Breadcrumbs />
                <Container className='section-center' data-plp-container>
                    <LeftContainer>
                        <FilterByText>Filter By</FilterByText>
                        <CategoryText>Category</CategoryText>
                        <List>
                            {categoryList &&
                                categoryList?.categories?.map((item, i) => (
                                    <Item
                                        subcategories={item.subcategories}
                                        id={item.id}
                                        name={item.name}
                                        key={i}
                                        onCategoryClick={(id) => getProducts(id)}
                                        isSelectedCategoryID={isSelectedCategoryID}
                                    />
                                ))}
                        </List>
                        {false && <FacetFilter />}
                    </LeftContainer>

                    {!isProductListLoading &&
                        productListData &&
                        productListData.products &&
                        productListData.products.length > 0 && (
                            <RightContainer>
                                {false && (
                                    <BreadCrumbContainer>
                                        <BreadCrumbItems>
                                            <BreadCrumbUnOrderList>
                                                <BreadCrumbText>THERMOSTAT TYPE: </BreadCrumbText>
                                                <BreadCrumbListItem>
                                                    <BreadCrumbButton>
                                                        Communication
                                                        <BreadCrumbButtonIcon></BreadCrumbButtonIcon>
                                                    </BreadCrumbButton>
                                                </BreadCrumbListItem>
                                                <BreadCrumbListItem>
                                                    <BreadCrumbButton>
                                                        Digital
                                                        <BreadCrumbButtonIcon></BreadCrumbButtonIcon>
                                                    </BreadCrumbButton>
                                                </BreadCrumbListItem>
                                            </BreadCrumbUnOrderList>
                                        </BreadCrumbItems>
                                        <BreadCrumbClearAllButton>
                                            <div>Clear All Filters</div>
                                        </BreadCrumbClearAllButton>
                                    </BreadCrumbContainer>
                                )}

                                <ProductContainer>
                                    <ProductLayoutContainer>
                                        {productListData &&
                                            productListData?.products?.map(
                                                (product: Product, index) => (
                                                    <ProductCard
                                                        key={index}
                                                        product={product}
                                                        index={index}
                                                    />
                                                ),
                                            )}
                                    </ProductLayoutContainer>
                                </ProductContainer>

                                <Flex flexDirection={'row'} justifyContent={'center'}>
                                    <MyPaginate
                                        previousLabel={
                                            <img width={12} height={24} src={leftCarouselArrow} />
                                        }
                                        nextLabel={
                                            <img width={12} height={24} src={rightCarouselArrow} />
                                        }
                                        pageCount={productListData.pagination.totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePaginationOnClick}
                                        forcePage={isCurrentPageNumber}
                                    />
                                </Flex>
                            </RightContainer>
                        )}

                    {!isProductListLoading &&
                        productListData &&
                        productListData.products &&
                        !productListData.products.length && (
                            <>
                                <StyledHeading
                                    paddingTop={['medium', 'medium', 'large', 'large']}
                                    paddingBottom={['xLarge', 'xLarge', 'large', 'large']}
                                    fontSize='2.25rem'
                                    color='textHeading'
                                    textAlign={['center', 'center', 'center', 'center']}>
                                    {'No Result Found'}
                                </StyledHeading>
                            </>
                        )}
                    {isProductListLoading && <PageLoading />}
                </Container>
            </Box>
        </Box>
    );
};

export default ProductListPage;

import { GraphQLResponse, RequestParameters, Variables } from 'relay-runtime';

import  {
  USE_PERSISTED_QUERIES,
  PERSISTED_QUERIES_APP_NAME,
  PERSISTED_QUERIES_APP_VERSION,
} from 'config';

interface FetchQueryFactory {
  accessToken?: string;
  url: string;
}

export const encodeId = ({ appName, appVersion, md5Hash }) =>
  btoa(`${appName}:${appVersion}:${md5Hash}`);

const formatUrl = (url: string, operation: RequestParameters) =>
  USE_PERSISTED_QUERIES
    ? `${url}/${encodeId({
        appName: PERSISTED_QUERIES_APP_NAME,
        appVersion: PERSISTED_QUERIES_APP_VERSION,
        md5Hash: operation.id,
      })}`
    : url;

export function fetchQueryFactory({ accessToken, url }: FetchQueryFactory) {
  return async function fetchQuery(
    operation: RequestParameters,
    variables: Variables
  ): Promise<GraphQLResponse> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    console.log(url, accessToken)

    const response = await fetch(formatUrl(url, operation), {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...(!USE_PERSISTED_QUERIES && { query: operation.text }),
        variables,
      }),
    });

    if (!response.ok) {
      let message = '';
      switch (response.status) {
        case 401:
          message = 'Unauthorized status from GraphQL';
          break;
        case 404:
          message = 'Wrong resource URL';
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unknown status ' + response.status;
      }
      throw new Error(message);
    }

    const result = await response.json();

    return result;
  };
}

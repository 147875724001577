/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/* @relayHash 58cb31c702cb65af1761bcccdb4d258a */

import { ConcreteRequest } from "relay-runtime";

export type ApprovalStatus = "ACTIVE" | "ARCHIVED" | "INACTIVE" | "PENDING" | "%future added value";
export type CountryCode = "AE" | "AR" | "AT" | "AU" | "BE" | "BG" | "BR" | "CA" | "CH" | "CL" | "CN" | "CO" | "CR" | "CZ" | "DE" | "DK" | "EC" | "EE" | "EG" | "ES" | "FI" | "FL" | "FR" | "GB" | "GR" | "GT" | "HR" | "HU" | "IE" | "IN" | "IT" | "JP" | "KE" | "KR" | "LT" | "LU" | "LV" | "MA" | "MX" | "MY" | "NL" | "NO" | "NZ" | "PA" | "PE" | "PL" | "PT" | "RO" | "SA" | "SE" | "SG" | "SI" | "SK" | "TN" | "TR" | "UA" | "UK" | "UM" | "US" | "VG" | "VI" | "ZA" | "%future added value";
export type PartnerProgramEnrollmentBillingCycle = "ANNUAL" | "MONTHLY" | "%future added value";
export type PartnerProgramEnrollmentMarket = "CAN_001" | "NA_001" | "%future added value";
export type PartnerProgramEnrollmentStatus = "ACTIVE" | "CANCELLED" | "EXPIRED" | "NOT_STARTED" | "PENDING" | "WITHDRAWN" | "%future added value";
export type ProductListPageQueryVariables = {};
export type ProductListPageQueryResponse = {
    readonly viewer: {
        readonly id: string | null;
        readonly countryCode: CountryCode | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly contactEmail: string | null;
        readonly primaryPhoneNumber: string | null;
        readonly partnerUsers: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly partnerAccount?: {
                        readonly id: string | null;
                        readonly status: ApprovalStatus | null;
                        readonly name: string | null;
                        readonly billToAddress: {
                            readonly addressLine1: string | null;
                            readonly addressLine2: string | null;
                            readonly city: string | null;
                            readonly stateProvinceRegionCode: string | null;
                            readonly zipPostalCode: string | null;
                            readonly countryCode: CountryCode | null;
                            readonly mailingAddressName: string | null;
                            readonly contactName: string | null;
                        } | null;
                        readonly shipToAddress: {
                            readonly addressLine1: string | null;
                            readonly addressLine2: string | null;
                            readonly city: string | null;
                            readonly stateProvinceRegionCode: string | null;
                            readonly zipPostalCode: string | null;
                            readonly countryCode: CountryCode | null;
                            readonly mailingAddressName: string | null;
                            readonly contactName: string | null;
                        } | null;
                        readonly productMarkets: ReadonlyArray<{
                            readonly name: string | null;
                        } | null> | null;
                        readonly partnerProgramEnrollments: {
                            readonly edges: ReadonlyArray<{
                                readonly node: {
                                    readonly id: string | null;
                                    readonly __typename: string;
                                    readonly partnerProgram: {
                                        readonly name: string | null;
                                    } | null;
                                    readonly status: PartnerProgramEnrollmentStatus | null;
                                    readonly activationDate: string | null;
                                    readonly billingCycle: PartnerProgramEnrollmentBillingCycle | null;
                                    readonly enrollmentMarket: PartnerProgramEnrollmentMarket | null;
                                    readonly purchaseDate: string | null;
                                    readonly cancellationDate: string | null;
                                    readonly cancellationReason: string | null;
                                    readonly autoRenew: boolean | null;
                                    readonly subscriptionEndDate: string | null;
                                    readonly billableQuantity: number | null;
                                    readonly logoApproved?: boolean | null | undefined;
                                };
                            } | null>;
                        } | null;
                    } | null | undefined;
                };
            } | null>;
        } | null;
        readonly phoneNumbers: ReadonlyArray<{
            readonly number: string | null;
        } | null> | null;
    } | null;
};
export type ProductListPageQuery = {
    readonly response: ProductListPageQueryResponse;
    readonly variables: ProductListPageQueryVariables;
};



/*
query ProductListPageQuery {
  viewer {
    id
    countryCode
    firstName
    lastName
    contactEmail
    primaryPhoneNumber
    partnerUsers {
      edges {
        node {
          __typename
          ... on PartnerUser {
            partnerAccount {
              id
              status
              name
              billToAddress {
                addressLine1
                addressLine2
                city
                stateProvinceRegionCode
                zipPostalCode
                countryCode
                mailingAddressName
                contactName
              }
              shipToAddress {
                addressLine1
                addressLine2
                city
                stateProvinceRegionCode
                zipPostalCode
                countryCode
                mailingAddressName
                contactName
              }
              productMarkets {
                name
                id
              }
              partnerProgramEnrollments {
                edges {
                  node {
                    id
                    __typename
                    partnerProgram {
                      __typename
                      name
                      id
                    }
                    status
                    activationDate
                    billingCycle
                    enrollmentMarket
                    purchaseDate
                    cancellationDate
                    cancellationReason
                    autoRenew
                    ... on HomeownerAppBrandingPartnerProgramEnrollment {
                      logoApproved
                    }
                    subscriptionEndDate
                    billableQuantity
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    phoneNumbers {
      number
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactEmail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryPhoneNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "addressLine1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "addressLine2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateProvinceRegionCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipPostalCode",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mailingAddressName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contactName",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "billToAddress",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "shipToAddress",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = [
  (v7/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activationDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingCycle",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enrollmentMarket",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancellationDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancellationReason",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoRenew",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionEndDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableQuantity",
  "storageKey": null
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoApproved",
      "storageKey": null
    }
  ],
  "type": "HomeownerAppBrandingPartnerProgramEnrollment",
  "abstractKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "PhoneNumber",
  "kind": "LinkedField",
  "name": "phoneNumbers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CorePartnerUserConnection",
            "kind": "LinkedField",
            "name": "partnerUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorePartnerUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartnerAccount",
                            "kind": "LinkedField",
                            "name": "partnerAccount",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductMarket",
                                "kind": "LinkedField",
                                "name": "productMarkets",
                                "plural": true,
                                "selections": (v11/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PartnerProgramEnrollmentConnection",
                                "kind": "LinkedField",
                                "name": "partnerProgramEnrollments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PartnerProgramEnrollmentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v0/*: any*/),
                                          (v12/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "partnerProgram",
                                            "plural": false,
                                            "selections": (v11/*: any*/),
                                            "storageKey": null
                                          },
                                          (v6/*: any*/),
                                          (v13/*: any*/),
                                          (v14/*: any*/),
                                          (v15/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          (v19/*: any*/),
                                          (v20/*: any*/),
                                          (v21/*: any*/),
                                          (v22/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PartnerUser",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProductListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CorePartnerUserConnection",
            "kind": "LinkedField",
            "name": "partnerUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorePartnerUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartnerAccount",
                            "kind": "LinkedField",
                            "name": "partnerAccount",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductMarket",
                                "kind": "LinkedField",
                                "name": "productMarkets",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PartnerProgramEnrollmentConnection",
                                "kind": "LinkedField",
                                "name": "partnerProgramEnrollments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PartnerProgramEnrollmentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v0/*: any*/),
                                          (v12/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "partnerProgram",
                                            "plural": false,
                                            "selections": [
                                              (v12/*: any*/),
                                              (v7/*: any*/),
                                              (v0/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v6/*: any*/),
                                          (v13/*: any*/),
                                          (v14/*: any*/),
                                          (v15/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          (v19/*: any*/),
                                          (v20/*: any*/),
                                          (v21/*: any*/),
                                          (v22/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PartnerUser",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "58cb31c702cb65af1761bcccdb4d258a",
    "metadata": {},
    "name": "ProductListPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
(node as any).hash = 'dd9a2b5f72ec71d555d1d060b3739bd4';
export default node;
